export const MENUITEMS = [
  {
    Items: [
      { path: '/my-stories', title: "MY STORIES", type: "link" },
      { path: '/contributors', title: "CONTRIBUTORS", type: "link" },
      { path: '/your-web-pages', title: "YOUR WEB PAGES", type: "link"},
      { path: 'https://giftyourstory.tawk.help', title: "FAQ", type: "link"},
    ]
  }
];

export const LOGOUTMENUITEMS = [
  {
    Items: [
      { path: '/my-stories', title: "LOGIN", type: "link" },
      // { path: 'https://app.giftyourstory.com/sign-up/', title: "BUY NOW", type: "link" },
      { path: 'https://gys.coalescets.com/', title: "BUY NOW", type: "link" },
      { path: 'https://giftyourstory.tawk.help', title: "FAQ", type: "link"},
    ]
  }
];