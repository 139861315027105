import Billing from "../Pages/Billing/Billing";
import MyDetails from "../Pages/MyDetails/MyDetails";
import Emails from "../Pages/Emails/Emails";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import MyStories from "../Pages/MyStories/MyStories";
import EditMyStories from "../Pages/EditMyStories/EditMyStories";
import AddQuestion from "../Pages/Question/AddQuestion";
import ViewQuestion from "../Pages/Question/ViewQuestion";
import JustWrite from "../Pages/Question/JustWrite";
import View from "../Pages/Question/View";
import Pdf from "../Pages/Question/Pdf";
import Edit from "../Pages/Question/Edit";
import Contributors from "../Pages/Contributors/Contributors";
import ContributorViewQuestion from "../Pages/Contributors/ContributorViewQuestion";
// import ContributorPage from "../Pages/Contributors/ContributorPage";
import OrderBooks from "../Pages/OrderBooks/OrderBooks";
// import AdditionalOrderBooks from "../Pages/OrderBooks/AddAdditionalOrderBook";
// import EditAdditionalOrderBooks from "../Pages/OrderBooks/EditAdditionalOrderBook";
import YourWebPages from "../Pages/YourWebPages/YourWebPages";
import ReadThisStory from "../Pages/YourWebPages/ReadThisStory";
import ViewThisStory from "../Pages/YourWebPages/ViewThisStory";
import SendQuestions from "../Pages/SendQuestions/SendQuestions";
import QuestionsSendThankYou from "../Pages/SendQuestions/QuestionsSendThankYou";
// import AddAdditionalOrderBook from "../Pages/OrderBooks/AddAdditionalOrderBook";
import Payment from "../Pages/Payment/Payment";


export const routes = [
  { path: `${process.env.PUBLIC_URL}/billing`, Component: <Billing /> },
  { path: `${process.env.PUBLIC_URL}/send-questions`, Component: <SendQuestions /> },
  { path: `${process.env.PUBLIC_URL}/thank-you-for-your-question`, Component: <QuestionsSendThankYou /> },
  { path: `${process.env.PUBLIC_URL}/my-details`, Component: <MyDetails /> },
  { path: `${process.env.PUBLIC_URL}/emails`, Component: <Emails /> },
  { path: `${process.env.PUBLIC_URL}/reset-password`, Component: <ResetPassword /> },
  { path: `${process.env.PUBLIC_URL}/my-stories`, Component: <MyStories /> },
  { path: `${process.env.PUBLIC_URL}/print-order-confirmed/:sessionId`, Component: <Payment /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/edit-my-stories`, Component: <EditMyStories /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/add-question`, Component: <AddQuestion /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/view-question`, Component: <ViewQuestion /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/just-write`, Component: <JustWrite /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/view-question/view/:id`, Component: <View /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/view-question/view`, Component: <View /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/view-question/pdf`, Component: <Pdf /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/view-question/edit`, Component: <Edit /> },
  { path: `${process.env.PUBLIC_URL}/my-stories/order-books`, Component: <OrderBooks /> },
  // { path: `${process.env.PUBLIC_URL}/my-stories/order-books/add-additional-order-book`, Component: <AddAdditionalOrderBook/> },
  // { path: `${process.env.PUBLIC_URL}/my-stories/order-books/edit-additional-order-book`, Component: <EditAdditionalOrderBooks /> },
  { path: `${process.env.PUBLIC_URL}/contributors`, Component: <Contributors /> },
  // { path: `${process.env.PUBLIC_URL}/contributors/:hashkey`, Component: <ContributorPage /> },
  { path: `${process.env.PUBLIC_URL}/view-question`, Component: <ContributorViewQuestion /> },
  { path: `${process.env.PUBLIC_URL}/your-web-pages`, Component: <YourWebPages /> },
  { path: `${process.env.PUBLIC_URL}/your-web-pages/read-this-story`, Component: <ReadThisStory /> },
  { path: `${process.env.PUBLIC_URL}/your-web-pages/view-this-story`, Component: <ViewThisStory /> },
  
];