import React, { useContext, useState } from 'react';
import CustomizerContext from '../../_helper/Customizer';
import SidebarMenuItems from './SidebarMenuItems';


const SidebarMenu = ({ setMainMenu, props, sidebartoogle, setNavActive, activeClass }) => {
  const { customizer } = useContext(CustomizerContext);
  const wrapper = customizer.settings.sidebar.type;
  const [margin, setMargin] = useState(0);

  return (
    <nav className="sidebar-main" id="sidebar-main">

      <div className="left-arrow"></div>
      <div className='container'>
        <div id="sidebar-menu"
          style={
            wrapper.split(' ').includes('horizontal-wrapper')
              ? { marginLeft: margin + 'px' }
              : { margin: '0px' }
          } >
          
          <ul className="sidebar-links custom-scrollbar">
            <li className="back-btn">
              <div className="mobile-back text-end">
                <span>{'Back'}</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
            </li>
            
            <SidebarMenuItems setMainMenu={setMainMenu} props={props} sidebartoogle={sidebartoogle} setNavActive={setNavActive} activeClass={activeClass} />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SidebarMenu;