import React from 'react'
import { H6 } from '../../../AbstractElements'

const NotificationSlider = () => {
  return (
    <div className="notification-slider overflow-hidden ">
      <div className="d-flex h-100">
        <H6 attrH6={{ className: 'mb-0 f-w-600' }}>
          <span
            className="f-light"
            style={{ color: '#890077', fontSize: '14px' }}
          >
            A unique gift that lasts
          </span>
        </H6>
      </div>
    </div>
  )
}

export default NotificationSlider
