import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const PrivateRoute = () => {
  const [token] = useState(localStorage.getItem('token'))
  const [authenticated, setAuthenticated] = useState(false)
  const location = useLocation();
  if(location.pathname.startsWith("/my-stories/view-question/view/")){
    localStorage.setItem("viewNo", location.pathname.replace("/my-stories/view-question/view/",""));
  }

  
  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')))
    localStorage.setItem('authenticated', authenticated)
    // localStorage.setItem("login", login);
    localStorage.setItem('login', token)
  }, [])

  return token || authenticated ? (
    <Outlet />
  ) : (
    <Navigate exact to={`${process.env.PUBLIC_URL}/Signin/`} />
  )
  // return login || authenticated ? <Outlet /> : <Navigate exact to={`${process.env.PUBLIC_URL}/Signin/`} />;
}

export default PrivateRoute
