import * as types from "./types";
import * as API_URL from "../../../env";


export const updateEmailFrequency = (frequency) => ({
    type: types.UPDATE_EMAIL_FREQUENCY,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/update-frequency",
        method: "PUT",
        body: frequency
    },
});
