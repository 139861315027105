import CreatePwd from '../Pages/Auth/CreatePwd';
import ForgetPwd from '../Pages/Auth/ForgetPwd';
import Maintenance from '../Pages/Auth/Maintenance';

// Signin & SignUp
// import SignIn from '../Pages/Auth/Signin/Signin';
import SignIn from '../Pages/Auth/Signin/Signin';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../Pages/Auth/ResetPassword/ResetPassword';
import SignUp from '../Pages/Auth/SignUp/Singup';
import Logout from '../Pages/Auth/Logout/Logout';
// import Contributor from "../Pages/Contributors/ContributorPage";
import ContributorDashboard from "../Pages/Contributors/ContributorDashboard";
import EditQuestion from "../Pages/Contributors/contributorQuestion";
import ContributorView from "../Pages/Contributors/viewContributorQuestion";


export const authRoutes = [
  { path: '/Signin', name: 'Signin', Component: <SignIn /> },
  { path: '/ForgotPassword', name: 'ForgotPassword', Component: <ForgotPassword /> },
  { path: '/ResetPassword/:hashkey', name: 'ResetPassword', Component: <ResetPassword /> },
  { path: '/Singup', name: 'Singup', Component: <SignUp /> },
  { path: '/Logout', name: 'Logout', Component: <Logout /> },
  { path: `/ContributorDashboard/:hashkey`, name: 'contributors', Component: <ContributorDashboard /> },
  { path: `/question-details`, Component: <EditQuestion /> },
  { path: `/contributor-view-question`, Component: <ContributorView /> },
  { path: '/pages/authentication/forget-pwd', Component: <ForgetPwd /> },
  { path: '/pages/authentication/create-pwd', Component: <CreatePwd /> },
  { path: '/pages/authentication/maintenance', Component: <Maintenance /> },
];
