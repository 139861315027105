import * as types from "./types";
import * as API_URL from "../../../env";


export const updateMyDetails = (myDetails) => ({
    type: types.UPDATE_MY_DETAILS,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/update-user",
        method: "PUT",
        body: myDetails
    },
});
