import React from 'react'

class Logout extends React.Component {
  componentDidMount() {
    this.logoutHandler()
  }

  logoutHandler = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('authenticated')
    localStorage.removeItem('user')
    localStorage.removeItem('authenticated')
    localStorage.removeItem('menus')
    localStorage.removeItem('countries')
    localStorage.removeItem('roles')
    localStorage.removeItem('selectedUserId')
    localStorage.removeItem('cities')
    localStorage.removeItem('states')
    localStorage.removeItem('selectedCountryDetailsId')
    localStorage.removeItem('selectedCustomerId')
    localStorage.removeItem('customers')
    localStorage.removeItem('currency')
    localStorage.removeItem('nationalitys')
    localStorage.removeItem('paymentType')
    /* Exhibition Specific */
    localStorage.removeItem('isExhi')
    localStorage.removeItem('auditCycle')
    localStorage.removeItem('selectedItemBarCode')
    localStorage.removeItem('selectedAuditId')
    localStorage.removeItem('sharedExhibitionsId')
    localStorage.removeItem('exhibitionsName')
    localStorage.removeItem('selectedBaggingCycleId')
    localStorage.removeItem('selectedInvoiceNo')
    localStorage.removeItem('selectedReceiptNo')
    localStorage.removeItem('selectedBaggingCycleName')
    localStorage.removeItem('selectedInvoiceStatus')
    localStorage.removeItem('selectedReceiptStatus')
    localStorage.removeItem('article')
    localStorage.removeItem('category')
    localStorage.removeItem('language')
    localStorage.removeItem('selectedStoryId')
    localStorage.removeItem('selectedQuestionId')
    localStorage.removeItem('question')
    localStorage.removeItem('collection')
    localStorage.removeItem('login')
    localStorage.removeItem('login')
    localStorage.removeItem('contributor')
    localStorage.removeItem('subCollection')
    localStorage.removeItem('allQuestionData')
    localStorage.removeItem('selectedIsBagging')
    localStorage.removeItem('exhibitionCountryDetails')
    localStorage.removeItem('activeFullIndex')
    localStorage.removeItem('activeIndex')
    localStorage.removeItem('activeInsertImageIndex')
    localStorage.removeItem('activeNoImageIndex')
    localStorage.removeItem('i18nextLng')
    localStorage.removeItem('isFullImage')
    localStorage.removeItem('isInsertImage')
    localStorage.removeItem('isNoImage')
    localStorage.removeItem('isSpanVisible')
    localStorage.removeItem('selectedColor')
    localStorage.removeItem('questionOrder')

    window.open('/Signin/', '_self')
  }
  render() {
    return <span></span>
  }
}

export default Logout
