import React, { Fragment } from 'react'
import { Container, Card, CardBody, Col, Form, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as API_URL from '../../env.js'
import request from '../../utils/request'
import GoogleFontLoader from 'react-google-font-loader'
import '../Menu.css'
import { connect } from 'react-redux'
import { myStoryOperations } from '../../state/ducks/story'
import ViewBillingData from './ViewbillingData'

class Billing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUserData: {},
      selectedUserOrderData: [],
      selectedGiftUserData: {},
      selectedGiftUserOrderData: [],
      selectedMenu: '',
      selectedStoryData: '',
      giftUserOrderData: [],
      giftedUserOrderData: {},
      selectedIndex: null,
      detailsVisibility: [],
    }
    this.toggleTwoDetails = this.toggleTwoDetails.bind(this)
  }

  toggleTwoDetails = (index) => {
    this.setState((prevState) => {
      const newVisibility = [...prevState.detailsVisibility]
      newVisibility[index] = !newVisibility[index]
      return { detailsVisibility: newVisibility }
    })
  }

  async componentDidMount() {
    try {
      const { loggedInUserId } = this.props
      if (!loggedInUserId) {
        console.log('No user ID found')
        return
      }
      const storyResponse = await this.props.listMyStory({
        id: loggedInUserId,
      })
      this.setState({ selectedStoryData: storyResponse.payload })

      // this.loadLocalStorageData();
      //const questionId = localStorage.getItem('selectedQuestionId')
      const options = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/order-details`,
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'),
        },
      }
      let response = await request(options)

      // Update state with user data
      this.setState({
        selectedUserData: response.payload.user,
        selectedUserOrderData: response.payload.userOrders,
      })

      const giftOrderDetails = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/gift-order-details`,
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'),
        },
      }
      let giftOrderDetailsResponse = await request(giftOrderDetails)
      this.setState({
        selectedGiftUserData: giftOrderDetailsResponse.payload?.user,
        selectedGiftUserOrderData: giftOrderDetailsResponse.payload?.userOrders,
      })
      const giftUserOrderDetails = {
        url: API_URL.BACKEND_SERVICE_API_URL + `/user/giftUser-order-details`,
        method: 'GET',
        headers: {
          authorization: localStorage.getItem('token'),
        },
      }

      let giftUserOrderDetailsResponse = await request(giftUserOrderDetails)

      this.setState({
        giftUserOrderData:
          giftUserOrderDetailsResponse.payload?.giftUserOrdersDetails,
        giftedUserOrderData: giftUserOrderDetailsResponse.payload?.userDetails,
      })
    } catch (err) {
      console.log(err)
    }
  }

  onSendQuestions(e) {
    const id = e.currentTarget.getAttribute('data-test-id')
    localStorage.setItem('selectedGiftUserId', id)  
    window.open('/send-questions/', '_self')
  }

  onViewMyStory(e) {
    // const id = e.currentTarget.getAttribute('data-test-id')
    // localStorage.setItem('selectedGiftUserId', id)
    window.open('/my-stories/', '_self')
  }
  handleMenuClick = (menu) => {
    this.setState({ selectedMenu: menu })
  }

  render() {
    const {
      detailsVisibility,
      selectedMenu,
      selectedGiftUserOrderData,
      selectedUserOrderData,
      selectedStoryData,
      giftUserOrderData,
      giftedUserOrderData,
    } = this.state

    const filteredGiftUserOrderData = selectedGiftUserOrderData.filter(
      (order) => order.giftUserName && order.giftUserName.trim() !== ''
    )

    const ulStyle = {
      display: 'flex',
      color: '#a186be',
      listStyle: 'none',
      padding: 0,
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '300',
      fontFamily: 'Fira Sans',
      fontSize: '.8rem',
    }

    const spanStyle = {
      background: '#fdc1ea',
      display: 'inline-block',
      height: '20px',
      width: '1px',
      margin: '0 20px',
    }

    const liStyle = {
      cursor: 'pointer',
      paddingBottom: '5px', // Add some padding to make the border more visible
    }

    const iconStyle = (
      <span
        style={{
          content: '\f345',
          fontFamily: 'dashicons',
          fontSize: 'px',
          width: '12px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="feather feather-chevron-right"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
        >
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </span>
    )
    const discountValue =
      selectedUserOrderData.length > 0 ? selectedUserOrderData[0].discount : ''
    const userDataLength = selectedUserOrderData.length > 0

    return (
      <Fragment>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [300, 700, 400],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 300 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  My Account
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
                <ul className="mt-4 myAccountMenu" style={ulStyle}>
                  <li
                    className="current-item"
                    style={
                      selectedMenu === 'billing' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('billing')}
                  >
                    <Link
                      to="/billing/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      BILLING
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === 'my-details' ? { ...liStyle } : liStyle
                    }
                    onClick={() => this.handleMenuClick('my-details')}
                  >
                    <Link
                      to="/my-details/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      MY DETAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={selectedMenu === 'emails' ? { ...liStyle } : liStyle}
                    onClick={() => this.handleMenuClick('emails')}
                  >
                    <Link
                      to="/emails/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      EMAILS
                    </Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li
                    style={
                      selectedMenu === 'reset-password'
                        ? { ...liStyle }
                        : liStyle
                    }
                    onClick={() => this.handleMenuClick('reset-password')}
                  >
                    <Link
                      to="/reset-password/"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: '14.4px',
                      }}
                    >
                      RESET PASSWORD
                    </Link>
                  </li>
                </ul>
              </Row>
              <Row>
                <Col md="2"></Col>
                <Col md="8">
                  <div
                    className="billingMyStoryTitle"
                    style={{
                      fontSize: '17px',
                      fontWeight: '400',
                      fontFamily: 'Martel',
                      borderBottom: '1px solid #fdc1ea',
                      paddingBottom: '12px',
                      marginBottom: '30px',
                    }}
                  >
                    {userDataLength ? 'My Stories' : 'Stories gifted to me'}
                  </div>
                </Col>
                <Col md="2"></Col>

                <Col md="2"></Col>
                <Col md="8">
                  {userDataLength
                    ? selectedUserOrderData.map((order, index) =>
                      !order.giftUser.billingFirstName ? (
                        <ViewBillingData
                          title={order.title}
                          packageName={order.packages[0]?.package}
                          expiryDate={order.expiryDate}
                          orderId={order.orderId}
                          subtotal={order.subtotal}
                          total={order.total}
                          discountValue={discountValue}
                          numberOfItems={order.numberOfItems}
                          timeOfReceipt={order.timeOfReceipt}
                          index={index}
                          selectedIndex={this.state.selectedIndex}
                          setSelectedIndex={(selectIndex) =>
                            this.setState((prevState) => ({
                              ...prevState,
                              selectedIndex: selectIndex,
                            }))
                          }
                          isGifted={!!order.giftUser.billingFirstName}
                        />
                      ) : (
                        ''
                      )
                    )
                    : giftUserOrderData.map((order) => (
                      <ViewBillingData
                        title={selectedStoryData.title}
                        email={giftedUserOrderData.email}
                        packageName={order.packages[0]?.package}
                        userName={giftedUserOrderData.userName}
                        expiryDate={order.expiryDate}
                        isGifted={true}
                      />
                    ))}
                </Col>
                <Col md="2"></Col>
                <Col md="2"></Col>
                <Col md="8">
                  {userDataLength && (
                    <div
                      style={{
                        fontSize: '1.25rem',
                        borderBottom: '2px solid #fdc1ea',
                        paddingBottom: '12px',
                        marginBottom: '30px',
                        fontFamily: 'Martel',
                      }}
                    >
                      Stories I've gifted
                    </div>
                  )}
                </Col>
                <Col md="2"></Col>
                <Col md="2"></Col>
                <Col md="8">
                  {filteredGiftUserOrderData.length > 0
                    ? filteredGiftUserOrderData.map((orderData, index) => (
                      <Card key={index}>
                        <Row className="billingMyStoryGrid row mobileCenterGiftedTo">
                          <Col
                            xs={10}
                            style={{
                              background: '#F7EBF3',
                              padding: '2% 0% 3% 4%',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '30px',
                                fontFamily: 'Martel',
                              }}
                            >
                              Gifted to {orderData.giftUserName || ''} 
                            </div>
                            <div
                              style={{
                                marginBottom: '1rem',
                                marginTop: '0',
                                fontSize: '17px',
                                color: '#615F67',
                              }}
                            >
                              Email of the user {' '}
                              <strong
                                style={{ color: '#615F67', fontSize: '17px' }}
                              >
                                {orderData.giftUserEmail || ''}
                              </strong>
                            </div>

                            <div
                              style={{
                                marginBottom: '1rem',
                                marginTop: '0',
                                fontSize: '17px',
                                color: '#615F67',
                              }}
                            >
                              Package{' '}
                              <strong
                                style={{ color: '#615F67', fontSize: '17px' }}
                              >
                                {orderData.packages[0]?.package || ''}
                              </strong>
                            </div>
                            <div
                              onClick={() => this.toggleTwoDetails(index)}
                              style={{
                                color: '#8A1A75',
                                paddingBottom: '1%',
                                fontSize: '12px',
                              }}
                            >
                              {detailsVisibility[index]
                                ? 'VIEW PACKAGE DETAILS >'
                                : 'VIEW PACKAGE DETAILS >'}
                            </div>
                            <button
                              type="button"
                              className="custom-button"
                              data-test-id={orderData.orderId}
                              onClick={this.onSendQuestions}
                            >
                              {'Send questions '}
                              {iconStyle}
                            </button>
                          </Col>
                          <Col
                            xs={2}
                            style={{
                              background: '#EDD3E5',
                              textAlign: 'center',
                              padding: '6% 1% 3% 1%',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '15px',
                                fontFamily: 'Martel',
                              }}
                            >
                              Expiry
                            </div>
                            <div
                              style={{
                                fontSize: '24px',
                                fontFamily: 'Martel',
                              }}
                            >
                              {orderData.expiryDate || ''}
                            </div>
                          </Col>

                          {detailsVisibility[index] && (
                            <Row className="time-of-receip">
                              <Col
                                xs={8}
                                style={{
                                  background: '#8A1A75',
                                  color: '#fff',
                                }}
                              >
                                <div style={{ padding: '1%' }}>
                                  Order ID:
                                  {orderData.orderId || ''}
                                </div>
                                {/* <div style={{ padding: "1%" }}>Invoice Number: GYS00190</div> */}
                              </Col>
                              <Col
                                xs={4}
                                style={{
                                  background: '#8A1A75',
                                  color: '#fff',
                                }}
                              >
                                <div style={{ padding: '2%' }}>
                                  Time of receipt:
                                  {orderData.timeOfReceipt || ''}
                                </div>
                                {/* <div style={{ padding: "2%" }}>Transaction ID: 4567787678987</div> */}
                              </Col>

                              <Col
                                xs={12}
                                style={{
                                  background: '#EEE',
                                  fontSize: '15px',
                                  padding: '2% 4%',
                                  borderBottom: '2px solid #FEC1EB',
                                }}
                              >
                                <div>
                                  <b>
                                    Your story -{orderData.giftUserName || ''}
                                  </b>
                                </div>
                                <div>
                                  You purchased a subscription including one
                                  book for{' '}
                                  <b>{orderData.giftUserName || ''}</b>
                                </div>
                                <div>
                                  Emailed to <b>{orderData.email || ''}</b>
                                </div>
                                <div>
                                  Package{' '}
                                  <b>
                                    {orderData.packages[0]?.package || ''}
                                  </b>
                                </div>
                              </Col>
                              <Col xs={9}> </Col>
                              {/* <Col xs={12} style={{ background: "#EEE", padding: "2% 4%", borderBottom: "2px solid #FEC1EB" }}>
              <div>Number of items: {selectedUserOrderData.length > 0 ? selectedUserOrderData[0].numberOfItems : ''}</div>
              <div>Number of printed books: 1</div>
            </Col> */}

                              {discountValue && (
                                <>
                                  <Col
                                    xs={9}
                                    style={{
                                      background: '#EEE',
                                      fontSize: '17.6px',
                                      fontWeight: '200',
                                      padding: '1% 0%',
                                      borderBottom: '1px solid #00000026',
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: '#615F67',
                                        fontFamily: 'Fira Sans',
                                      }}
                                    >
                                      Discount
                                    </div>
                                  </Col>
                                  <Col
                                    xs={3}
                                    style={{
                                      background: '#EEE',
                                      fontSize: '17.6px',
                                      fontWeight: 'bolder',
                                      color: '#615F67',
                                      padding: '1% 0%',
                                      borderBottom: '1px solid #00000026',
                                      textAlign: 'right',
                                    }}
                                  >
                                    <div>£{discountValue}</div>
                                  </Col>
                                </>
                              )}

                              <Col
                                xs={9}
                                style={{
                                  background: '#EEE',
                                  fontSize: '17.6px',
                                  fontWeight: '200',
                                  padding: '1% 4%',
                                  borderBottom: '1px solid #00000026',
                                }}
                              >
                                <div
                                  style={{
                                    color: '#615F67',
                                    fontFamily: 'Fira Sans',
                                  }}
                                >
                                  Subtotal
                                </div>
                              </Col>
                              <Col
                                xs={3}
                                style={{
                                  background: '#EEE',
                                  fontSize: '17.6px',
                                  fontWeight: 'bolder',
                                  color: '#615F67',
                                  padding: '1% 4%',
                                  borderBottom: '1px solid #00000026',
                                  textAlign: 'right',
                                }}
                              >
                                <div>
                                  £
                                  {parseFloat(orderData?.subtotal).toFixed(
                                    2
                                  ) || ''}
                                </div>
                              </Col>

                              <Col
                                xs={9}
                                style={{
                                  background: '#EEE',
                                  fontSize: '17.6px',
                                  fontWeight: '200',
                                  padding: '1% 4%',
                                  borderBottom: '',
                                }}
                              >
                                <div
                                  style={{
                                    color: '#615F67',
                                    fontFamily: 'Fira Sans',
                                  }}
                                >
                                  Total
                                </div>
                              </Col>
                              <Col
                                xs={3}
                                style={{
                                  background: '#EEE',
                                  fontSize: '17.6px',
                                  fontWeight: 'bolder',
                                  color: '#615F67',
                                  padding: '1% 4%',
                                  borderBottom: '',
                                  textAlign: 'right',
                                }}
                              >
                                <div>
                                  £
                                  {parseFloat(orderData?.total).toFixed(2) ||
                                    ''}
                                </div>
                              </Col>

                              <Col
                                xs={12}
                                style={{ background: '#EEE', padding: '1%' }}
                              ></Col>
                            </Row>
                          )}
                        </Row>
                      </Card>
                    ))
                    : userDataLength && <p>No gifts found</p>}
                </Col>
                <Col md="2"></Col>
                <Col md="2"></Col>
                <Col md="8">
                  {userDataLength && (
                    <div
                      style={{
                        borderBottom: '1px solid #ccc',
                        paddingBottom: '12px',
                        marginBottom: '30px',
                      }}
                    ></div>
                  )}
                </Col>
              </Row>

              <Row className="mb-5">
                <ul style={ulStyle}>
                  <button
                    type="button"
                    className="custom-button"
                    onClick={this.onViewMyStory}
                  >
                    {'View My Stories '}
                    {iconStyle}
                  </button>
                </ul>
              </Row>
              <Row className="mt-2" style={{ marginBottom: '7%' }}>
                <ul style={ulStyle}>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com/">BUY A STORY</Link>
                  </li>
                  <span style={spanStyle}></span>
                  <li style={liStyle}>
                    <Link to="https://gys.coalescets.com/">GIFT A STORY</Link>
                  </li>
                </ul>
              </Row>
            </Form>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  SendAnInviteToStory: myStoryOperations.SendAnInviteToStory,
  listMyStory: myStoryOperations.listMyStory,
}
export default connect(mapStateToProps, mapDispatchToProps)(Billing)
// export default Billing;
