import React, { Fragment, createRef } from 'react'
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Label,
  Input,
} from 'reactstrap'
import story from '../../assets/images/datatable/story.png'
import imgDelete from '../../assets/images/datatable/imgDelete.png'
import '../../assets/scss/ToggleSwitch.css'
import Dropzone from 'react-dropzone'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux'
import { myStoryOperations } from '../../state/ducks/story'
import { UpdateMyBook } from '../../Constant'
import GoogleFontLoader from 'react-google-font-loader'
import '../Button.css'
import './TemplateSelector.css'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import TemplateSelector from './TemplateSelector'

class EditMyStories extends React.Component {
  constructor(props) {
    super(props)
    this.captureRef = createRef()
    this.state = {
      isCaptureReady: false,
      imageUrl: '',
      isChecked: false,
      selectedStoryData: {},
      selectedStyle: 'default',
      selectedColor: '#ffffff',
      selectedCover: '',
      isFullImage: false,
      isInsertImage: false,
      isNoImage: false,
      activeIndex: 0,
      activeFullIndex: 0,
      activeNoImageIndex: 0,
      activeInsertImageIndex: 0,
      isSpanVisible: false,
      textPosition: 'top',
      loading: false,
    }
    // Define images array with default values
    this.images = [
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-pink.jpg',
        alt: 'Pink',
        backgroundColor: '#f9c9ca',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-green.jpg',
        alt: 'Green',
        backgroundColor: '#7c8f77',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-black.jpg',
        alt: 'Black',
        backgroundColor: '#000',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-sub-title-white.jpg',
        alt: 'White',
        backgroundColor: '#ffffff',
      },
    ]
    this.fullImages = [
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/full-image-no-text.jpg',
        alt: 'Full image no text',
        backgroundColor: '#8a1a75',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/full-image.jpg',
        alt: 'Full image with text',
        backgroundColor: '#8a1a75',
      },
    ]
    this.noImages = [
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-blue.jpg',
        alt: 'Blue',
        backgroundColor: '#2eabad',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-pink.jpg',
        alt: 'Pink',
        backgroundColor: '#f9c9ca',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-black.jpg',
        alt: 'Black',
        backgroundColor: '#000',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/no-image-white.jpg',
        alt: 'White',
        backgroundColor: '#ffffff',
      },
    ]
    this.insertImages = [
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-green.jpg',
        alt: 'green',
        backgroundColor: '#7c8f77',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-black.jpg',
        alt: 'Black',
        backgroundColor: '#000',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-white.jpg',
        alt: 'White',
        backgroundColor: '#ffffff',
      },
      {
        src: 'https://giftyourstoryimgs.s3.eu-west-2.amazonaws.com/wp-content/uploads/2023/01/inset-image-pink.jpg',
        alt: 'Pink',
        backgroundColor: '#f9c9ca',
      },
    ]
    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this)
  }

  toggleCheckboxChange() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }))
  }

  handleStyleChange = (style) => {
    this.setState({ selectedStyle: style })
  }

  async componentDidMount(prevProps, prevState) {
    // console.log("capture======>>",this.captureRef.current);
    // console.log('Image src:', this.captureRef.current.querySelector('img').src);
    // console.log("Selected Story Data:", this.state.selectedStoryData);
    if (this.state.selectedStoryData.image && this.state.imageUrl) {
      URL.revokeObjectURL(
        this.state.selectedStoryData.image && this.state.imageUrl
      )
    }
    // if (prevState.imageUrl !== this.state.imageUrl && this.state.imageUrl) {
    //   this.imageObjectUrl = URL.createObjectURL(this.state.imageUrl);
    // }
    const activeIndex = localStorage.getItem('activeIndex')
    const selectedColor = localStorage.getItem('selectedColor')
    const activeFullIndex = localStorage.getItem('activeFullIndex')
    const isFullImage = localStorage.getItem('isFullImage') === 'true'
    const isSpanVisible = localStorage.getItem('isSpanVisible') === 'true'
    const activeNoImageIndex = localStorage.getItem('activeNoImageIndex')
    const isNoImage = localStorage.getItem('isNoImage') === 'true'
    const activeInsertImageIndex = localStorage.getItem(
      'activeInsertImageIndex'
    )
    const isInsertImage = localStorage.getItem('isInsertImage') === 'true'

    this.setState({
      activeIndex: activeIndex !== null ? Number(activeIndex) : 0, //this.state.activeIndex,
      selectedColor: selectedColor || '#FFFFFF', //this.state.selectedColor,
      activeFullIndex: activeFullIndex !== null ? Number(activeFullIndex) : 0, //this.state.activeFullIndex,
      isFullImage,
      isSpanVisible,
      activeNoImageIndex:
        activeNoImageIndex !== null ? Number(activeNoImageIndex) : 0, //this.state.activeNoImageIndex,
      isNoImage,
      activeInsertImageIndex:
        activeInsertImageIndex !== null ? Number(activeInsertImageIndex) : 0, //this.state.activeInsertImageIndex,
      isInsertImage,
      isChecked:
        story.show_public_bookshelf !== undefined
          ? story.show_public_bookshelf
          : false, // Default to false if undefined
    })
    const storyId = localStorage.getItem('selectedStoryId')
    // console.log("questionId===>>>>",storyId);
    const response = await this.props.getMyStory({ id: storyId })
    const storyData = response.payload
    // console.log('storyData===>>>', storyData)

    this.setState({
      selectedStoryData: storyData,
      imageUrl: storyData.image,
      selectedColor: storyData.cover_color,
      selectedCover: story.cover_page,
      textPosition: story.book_text_position || 'top',
      isChecked:
        storyData.show_public_bookshelf !== undefined
          ? Boolean(storyData.show_public_bookshelf)
          : false,
      // text: questionData.answer,
      // content: questionData.question,
    })
  }

  componentWillUnmount() {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl)
    }
  }
  handleInput = (e) => {
    const { id, value } = e.target
    this.setState((prevState) => ({
      selectedStoryData: {
        ...prevState.selectedStoryData,
        [id]: value,
      },
    }))
  }

  captureImage = () => {
    return new Promise((resolve, reject) => {
      // console.log("imgeUrl====>>>",this.state.imageUrl);
      let imageSrc = this.state.selectedStoryData.image || this.state.imageUrl || ''
      // console.log("imageSrc======>>>", imageSrc)
      if (imageSrc == '') {
        resolve(imageSrc);
      }
      else {
        imageSrc = typeof imageSrc == 'string' ? `${imageSrc}?t=${new Date().getTime()}` : URL.createObjectURL(imageSrc);
        console.log("imageSrc======>>>", imageSrc)
        const img = new Image()
        // console.log("img======>>>",img)
        img.crossOrigin = 'anonymous'

        img.src = imageSrc;
        // console.log("img======>>>",img.src)

        img.onload = async () => {
          try {
            //console.log("Image loaded successfully at", new Date().toISOString());
            // const element = document.querySelector("#capture");
            const element = this.captureRef.current
            // console.log("element===",element);
            if (!element) {
              console.error('Element not found!')
              return
            }

            // Clear the canvas before capturing the image
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')
            context.clearRect(0, 0, canvas.width, canvas.height)
            // Wait for the image to be completely loaded and rendered in the DOM
            setTimeout(async () => {
              try {
                console.log('Starting html2canvas at', new Date().toISOString())
                const canvas = await html2canvas(element, { useCORS: true })
                // console.log("canvas=====>>>>>>", canvas);
                const coverPageUrl = canvas.toDataURL('image/png')
                // console.log("Captured cover page URL:", coverPageUrl);
                resolve(coverPageUrl)
              } catch (canvasError) {
                console.error('Error capturing canvas:', canvasError)
                reject(canvasError)
              }
            }, 1000) // You might need to adjust this delay based on the rendering speed
          } catch (error) {
            reject(error)
          }
        }

        img.onerror = (error) => {
          console.error('Image load error:', error)
          reject(new Error(`Failed to load image from source: ${img.src}`))
        }
      }
    })
  }

  //   capturePDF = () => {
  //     const element = this.captureRef.current;
  //     if (!element) {
  //       console.error("Element not found!");
  //       return;
  //     }

  //     html2canvas(element, { scale: 2 }).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       const pdf = new jsPDF();
  //       const imgWidth = 210; // A4 width in mm
  //       const pageHeight = 295; // A4 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;

  //       pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         pdf.addPage();
  //         pdf.addImage(imgData, 'PNG', 0, -heightLeft, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       // Save PDF to Blob and create URL
  //       const pdfBlob = pdf.output('blob');
  //       const pdfUrl = URL.createObjectURL(pdfBlob);
  // console.log("pdfURl-====",pdfUrl);
  //       // Proceed with saving the PDF URL
  //       this.saveCoverPage(pdfUrl);
  //     });
  //   };

  saveCoverPage = async (coverPageUrl, pdfUrl) => {
    const {
      selectedStoryData,
      imageUrl,
      selectedColor,
      textPosition,
      isChecked,
    } = this.state
    let formData = new FormData()
    formData.append('id', selectedStoryData.id)
    formData.append('title', selectedStoryData.title)
    formData.append('subtitle', selectedStoryData.subtitle)
    formData.append('author', selectedStoryData.author)
    formData.append('cover_color', selectedColor)
    formData.append('cover_page', coverPageUrl)
    formData.append('cover_page_pdf_url', pdfUrl)
    formData.append('book_text_position', textPosition)
    formData.append('show_public_bookshelf', isChecked ? 1 : 0)

    if (imageUrl) {
      formData.append('cover_image_url', imageUrl)
    } else if (selectedStoryData.cover_image_url) {
      formData.append('cover_image_url', selectedStoryData.cover_image_url)
    } else {
      formData.append('cover_image_url', '')
    }

    try {
      let updateResponse = await this.props.updateMyStory(formData)
      toast.success(updateResponse.message, { autoClose: 10000 })
      window.open('/my-stories/', '_self')
    } catch (err) {
      console.log(err)
      toast.error(err.response.data.message, { autoClose: 10000 })
    }
  }

  onUpdate = async (e) => {
    this.setState({ loading: true })
    e.preventDefault()
    // this.captureImage();
    // this.capturePDF();
    this.setState({ isCaptureReady: true }, async () => {
      try {
        const coverPageUrl = await this.captureImage()
        // const pdfUrl = await this.capturePDF();
        await this.saveCoverPage(coverPageUrl)
        // await this.saveCoverPage();
      } catch (error) {
        console.error('Error during update:', error)
        this.setState({ loading: false })
      }
    })
  }

  handleColorChange = (color) => {
    this.setState({ selectedColor: color.hex })
  }

  onDropImages = (files) => {
    console.log('files=====>>>><><>', files)
    if (files && files.length !== 0) {
      const ALLOWED_FORMAT = 'image/jpg, image/jpeg, image/png'
      if (ALLOWED_FORMAT.includes(files[0].type)) {
        this.setState({ imageUrl: files[0] })
      } else {
        toast.error('This file format is not accepted.', { autoClose: 3000 })
      }
    }
  }

  handleDeleteImage = () => {
    this.setState({
      imageUrl: '',
      selectedStoryData: {
        ...this.state.selectedStoryData,
        image: ""
      }
    })
  }

  handleButtonClick = (index) => {
    if (index >= 0 && index < this.images.length) {
      this.setState(
        {
          isFullImage: false,
          isNoImage: false,
          isInsertImage: false,
          activeIndex: index,
          selectedColor: this.images[index].backgroundColor,
        },
        () => {
          // Callback after state is set
          localStorage.setItem('activeIndex', index)
          localStorage.setItem(
            'selectedColor',
            this.images[index].backgroundColor
          )
          localStorage.setItem('isFullImage', false)
          localStorage.setItem('isNoImage', false)
          localStorage.setItem('isInsertImage', false)
        }
      )
    }
  }

  handleImageButtonClick = (index) => {
    if (index >= 0 && index < this.fullImages.length) {
      this.setState(
        {
          activeFullIndex: index,
          isFullImage: true, //this.fullImages[index].alt !== "Full image no text",
          isNoImage: false,
          isInsertImage: false,
          isSpanVisible: this.fullImages[index].alt !== 'Full image no text',
        },
        () => {
          localStorage.setItem('activeFullIndex', index)
          localStorage.setItem('isFullImage', true)
          localStorage.setItem('isSpanVisible', false)
          localStorage.setItem('isNoImage', false)
          localStorage.setItem('isInsertImage', false)
        }
      )
    }
  }

  handleNoImageButtonClick = (index) => {
    if (index >= 0 && index < this.noImages.length) {
      const selectedImage = this.noImages[index]
      const borderColor =
        selectedImage.backgroundColor === '#ffffff' ? '#000' : '#fff' // Set border to black if background is white
      this.setState(
        {
          activeNoImageIndex: index,
          isNoImage: true,
          isFullImage: false,
          isInsertImage: false,
          selectedColor: selectedImage.backgroundColor,
          borderColor: borderColor,
        },
        () => {
          localStorage.setItem('activeNoImageIndex', index)
          localStorage.setItem('isNoImage', true)
          localStorage.setItem('isFullImage', false)
          localStorage.setItem('isInsertImage', false)
          localStorage.setItem('selectedColor', selectedImage.backgroundColor)
          localStorage.setItem('borderColor', borderColor)
        }
      )
    }
  }

  handleInsertImageButtonClick = (index) => {
    if (index >= 0 && index < this.insertImages.length) {
      this.setState(
        {
          activeInsertImageIndex: index,
          isInsertImage: true,
          isNoImage: false,
          isFullImage: false,
          selectedColor: this.insertImages[index].backgroundColor,
        },
        () => {
          localStorage.setItem('activeInsertImageIndex', index)
          localStorage.setItem('isInsertImage', true)
          localStorage.setItem('isNoImage', false)
          localStorage.setItem('isFullImage', false)
          localStorage.setItem(
            'selectedColor',
            this.insertImages[index].backgroundColor
          )
        }
      )
    }
  }
  // Utility function to determine text color based on background color
  getTextColor = (backgroundColor) => {
    // Convert hex to RGB
    let r = parseInt(backgroundColor.slice(1, 3), 16)
    let g = parseInt(backgroundColor.slice(3, 5), 16)
    let b = parseInt(backgroundColor.slice(5, 7), 16)

    // Calculate luminance
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

    // Return light or dark text color
    return luminance < 128 ? '#FFFFFF' : '#404040' // Light text on dark background, dark text on light background
  }
  // Utility function to determine border color based on background color
  getBorderColor = (backgroundColor) => {
    let r = parseInt(backgroundColor.slice(1, 3), 16)
    let g = parseInt(backgroundColor.slice(3, 5), 16)
    let b = parseInt(backgroundColor.slice(5, 7), 16)
    let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
    return luminance < 128 ? '#FFFFFF' : '#000000' // Light border on dark background, dark border on light background
  }

  handleTextPositionChange = (event) => {
    this.setState({ textPosition: event.target.value })
  }

  render() {
    const {
      imageUrl,
      selectedStoryData,
      selectedStyle,
      selectedColor,
      activeIndex,
      activeFullIndex,
      activeInsertImageIndex,
      activeNoImageIndex,
      textPosition,
      isFullImage,
    } = this.state
    const { isChecked } = this.state
    if (typeof isChecked !== 'boolean') {
      console.error('isChecked is not a valid boolean')
      return // Prevent submission if the value is invalid
    }
    const imageCheckUrl =
      typeof imageUrl == 'string'
        ? imageUrl
        : typeof imageUrl == 'object'
          ? URL.createObjectURL(imageUrl)
          : selectedStoryData.image || ''

    const textPositionStyles =
      textPosition === 'top'
        ? { top: '15%' } // adjust as needed
        : { top: '75%' } // adjust as needed
    const borderColor = this.getBorderColor(selectedColor)

    const textColor = this.getTextColor(selectedColor)

    const images = this.images // Safeguard for undefined images

    // Ensure `images` and `activeIndex` are valid
    if (
      !images ||
      images.length === 0 ||
      activeIndex < 0 ||
      activeIndex >= images.length
    ) {
      return <div>No images available</div> // Fallback UI when images are not available
    }

    const currentImage = images[activeIndex] || images[0]

    const fullImages = this.fullImages
    // Ensure `images` and `activeIndex` are valid
    if (
      !fullImages ||
      fullImages.length === 0 ||
      activeFullIndex < 0 ||
      activeFullIndex >= fullImages.length
    ) {
      return <div>No images available</div> // Fallback UI when images are not available
    }

    const currentFullImage = fullImages[activeFullIndex] || fullImages[0]

    const noImages = this.noImages
    // Ensure `images` and `activeIndex` are valid
    if (
      !noImages ||
      noImages.length === 0 ||
      activeNoImageIndex < 0 ||
      activeNoImageIndex >= noImages.length
    ) {
      return <div>No images available</div> // Fallback UI when images are not available
    }

    const currentNoImage = noImages[activeNoImageIndex] || noImages[0]

    const insertImages = this.insertImages
    // Ensure `images` and `activeIndex` are valid
    if (
      !insertImages ||
      insertImages.length === 0 ||
      activeInsertImageIndex < 0 ||
      activeInsertImageIndex >= insertImages.length
    ) {
      return <div>No images available</div> // Fallback UI when images are not available
    }

    const currentInsertImage =
      this.insertImages[activeInsertImageIndex] || insertImages[0]

    // console.log("====>>>",selectedStoryData);
    const coverImagePath = selectedStoryData.image
    return (
      <Fragment>
        <ToastContainer />
        <GoogleFontLoader
          fonts={[
            {
              font: 'Martel',
              weights: [300, 700],
            },
            {
              font: 'Fira Sans',
              weights: [200, 500],
            },
          ]}
        />
        <Container fluid={true}>
          <CardBody>
            <Form onSubmit={this.onUpdate}>
              <Row>
                <h2
                  className="mt-5"
                  style={{
                    textAlign: 'center',
                    font: 'normal normal 400 2.2rem/1.4 Martel',
                    color: '#000',
                  }}
                >
                  Edit your story details
                </h2>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #FEC1EB',
                      height: '1px',
                      width: '102px',
                      display: 'inline-block',
                    }}
                  ></span>
                </div>
              </Row>
              <Row className="mt-3">
                <Col
                  md="12"
                  style={{
                    background: '#EEE',
                    padding: '2% 0% 2% 2%',
                    fontFamily: 'Martel',
                  }}
                >
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={5}>
                      {this.state.isFullImage ? (
                        <>
                          <div
                            ref={this.captureRef}
                            style={{
                              backgroundImage: `url(${imageCheckUrl})`,
                              // background: "#fff",
                              height: '564px',
                              maxWidth: '400px',
                              position: 'relative', // Ensure the parent is positioned relative
                              boxShadow: '-4px 5px 12px 0px #bababa',
                              borderRadius: '2px',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              font: 'normal normal 300 1.1rem / 1.5 Fira Sans',
                            }}
                          >
                            {this.state.isSpanVisible && (
                              // <div
                              // style={{
                              //   left: "0",
                              //   top: "0",
                              //   right: "0",
                              //   alignItems: "center",
                              //   display: "flex",
                              //   flexDirection: "column",
                              //   position: "absolute",
                              //   bottom: "0",

                              //   }}>
                              <div
                                style={{
                                  background: '#fff',
                                  position: 'absolute',
                                  left: '10%',
                                  right: '10%',
                                  padding: '2%',
                                  border: '1px solid #000',
                                  textAlign: 'center',
                                  // display: 'flex',
                                  ...textPositionStyles, // Apply conditional styles
                                  // transform: "translateY(-50%)", // Adjust centering for better visibility
                                }}
                              >
                                <span
                                  className="preview-title"
                                  style={{
                                    textTransform: 'uppercase',
                                    fontSize: '1.75em',
                                    letterSpacing: '.05em',
                                    lineHeight: '1.2',
                                    display: 'block',
                                    marginBottom: '10px', // add margin to separate the title and author
                                  }}
                                >
                                  {selectedStoryData.title || ''}
                                </span>
                                <span
                                  style={{
                                    fontSize: '1.1em',
                                    letterSpacing: '.05em',
                                    lineHeight: '1.2',
                                    display: 'block',
                                  }}
                                >
                                  {selectedStoryData.subtitle || ''}
                                </span>
                              </div>
                              // </div>
                            )}
                          </div>
                        </>
                      ) : this.state.isInsertImage ? (
                        <>
                          <div
                            ref={this.captureRef}
                            // id="capture-element"
                            style={{
                              color: textColor,
                              textAlign: 'center',
                              background: selectedColor,
                              height: '564px',
                              maxWidth: '400px',
                              position: 'relative',
                              boxShadow: '-4px 5px 12px 0px #bababa',
                              borderRadius: '2px',
                              font: 'normal normal 300 1.1rem / 1.5 Fira Sans',
                              fontColor: '#404040',
                            }}
                          >
                            <div
                              style={{
                                // color: textColor,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                right: '9%',
                                left: '9%',
                                top: '10%',
                                bottom: 0,
                              }}
                            >
                              <div
                                style={{
                                  // color: textColor,
                                  height: '82%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  // width: "85%",
                                  marginBottom: '3%',
                                }}
                              >
                                <img
                                  src={imageCheckUrl}
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    // objectFit: "cover",
                                  }}
                                  alt="cover"
                                  onLoad={() => {
                                    console.log('Image loaded for capture')
                                    // this.captureImage();
                                  }}
                                />
                              </div>
                              <span
                                style={{
                                  color: textColor,
                                  fontSize: '1.4em',
                                  lineHeight: '1',
                                  textTransform: 'uppercase',
                                  letterSpacing: '.05em',
                                }}
                              >
                                {selectedStoryData.title || ''}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : this.state.isNoImage ? (
                        <>
                          <div
                            ref={this.captureRef}
                            // id="capture-element"
                            style={{
                              color: textColor,
                              background: selectedColor,
                              height: '564px',
                              maxWidth: '400px',
                              position: 'relative',
                              boxShadow: '-4px 5px 12px 0px #bababa',
                              borderRadius: '2px',
                              font: 'normal normal 300 1.1rem / 1.5 Fira Sans',
                            }}
                          >
                            <div
                              style={{
                                color: textColor,
                                content: ' ',
                                display: 'block',
                                height: '100%',
                                width: '50%',
                                borderRight: `1px solid ${this.state.selectedColor === '#ffffff'
                                  ? '#000'
                                  : '#fff'
                                  }`,
                                position: 'absolute',
                                // borderColor: '#fff',
                              }}
                            ></div>
                            <div
                              style={{
                                background: selectedColor,
                                color: textColor,

                                // background: "#fff",
                                position: 'absolute',
                                top: '45%',
                                left: '10%',
                                right: '10%',
                                padding: '5%',
                                border: `1px solid ${this.state.selectedColor === '#ffffff'
                                  ? '#000'
                                  : '#fff'
                                  }`,
                                textAlign: 'center',
                                // borderColor: '#fff',
                              }}
                            >
                              <span
                                style={{
                                  textTransform: 'uppercase',
                                  fontSize: '1.75em',
                                  letterSpacing: '.05em',
                                  lineHeight: '1.2',
                                }}
                              >
                                {selectedStoryData.title || ''}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            ref={this.captureRef}
                            // id="capture-element"
                            style={{
                              background: selectedColor,
                              color: textColor,
                              height: '564px',
                              maxWidth: '400px',
                              position: 'relative',
                              boxShadow: '-4px 5px 12px 0px #bababa',
                              borderRadius: '2px',
                              font: 'normal normal 300 1.1rem / 1.5 Fira Sans',
                              fontColor: '#404040',
                              textAlign: 'center',
                              marginTop: '10%',
                              minHeight: '16%',
                            }}
                          >
                            <div
                              style={{
                                // color: textColor,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                right: '9%',
                                left: '9%',
                                top: '10%',
                                bottom: 0,
                              }}
                            >
                              <div
                                style={{
                                  // color: textColor,
                                  marginTop: '10%',
                                  minHeight: '16%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  // letterSpacing: ".05em",
                                  // textTransform: 'uppercase',
                                  textAlign: 'center',
                                  justifyContent: 'flex-start',
                                  // marginBottom: "0.15rem",
                                  // lineHeight: "1",
                                  // fontSize: "1.5em",
                                  // padding: "20% 10% 0% 13%",
                                }}
                              >
                                <span
                                  style={{
                                    color: textColor,
                                    fontSize: '1.5em',
                                    marginBottom: '0.15rem',
                                    lineHeight: '1',
                                    letterSpacing: '.05em',
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                  }}
                                >
                                  {selectedStoryData.title || ''}
                                </span>
                                <span
                                  style={{
                                    color: textColor,
                                    // fontSize: '1.5em',
                                    marginBottom: '0.15rem',
                                    lineHeight: '1',
                                    letterSpacing: '.05em',
                                    // textTransform: 'uppercase',
                                    textAlign: 'center',
                                  }}
                                >
                                  {selectedStoryData.subtitle || ''}
                                </span>
                              </div>

                              <div
                                style={{
                                  // padding: "10% 10% 6% 13%",
                                  // bottom: "0",
                                  // alignItems: "center",
                                  height: '65%',
                                  marginBottom: '1%',
                                  // alignItems: "center",
                                  // display: "flex",
                                  // flexDirection: "column",
                                  // position: "absolute",
                                  // right: "9%",
                                  // left: "9%",
                                  // top: "10%",
                                  // bottom: 0,
                                }}
                              >
                                <img
                                  src={imageCheckUrl}
                                  style={{
                                    maxHeight: '100%',
                                    width: 'auto',
                                    maxWidth: '100%,',
                                    height: 'auto',
                                    verticalAlign: 'middle',
                                    // width: "290px",
                                    // height: "300px",
                                    // objectFit: "contain",
                                  }}
                                  alt="cover"
                                  onLoad={() =>
                                    console.log('Image loaded for capture')
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  color: textColor,
                                  fontSize: '12px',
                                  fontWeight: '400',
                                  letterSpacing: '0.05em',
                                  textTransform: 'uppercase',
                                  paddingTop: '2px',
                                  // position: "absolute",
                                  bottom: '30px',
                                  textAlign: 'center',
                                }}
                              >
                                {selectedStoryData.author || ''}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>

                    <Col xs={5} className="mt-3">
                      <Label>Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value={selectedStoryData.title || ''}
                        onChange={this.handleInput}
                      />
                      {!this.state.isInsertImage && !this.state.isNoImage && (
                        <>
                          <Label className="mt-3">Subtitle (optional)</Label>
                          <Input
                            type="text"
                            name="subtitle"
                            id="subtitle"
                            value={selectedStoryData.subtitle || ''}
                            onChange={this.handleInput}
                          />
                        </>
                      )}
                      {!this.state.isFullImage && (
                        <>
                          <Label className="mt-3">Author(s)</Label>
                          <Input
                            type="text"
                            name="author"
                            id="author"
                            value={selectedStoryData.author || ''}
                            onChange={this.handleInput}
                          />
                        </>
                      )}

                      {this.state.isFullImage &&
                        this.fullImages[activeFullIndex].alt ===
                        'Full image with text' && (
                          <div
                            style={{
                              flexDirection: 'column',
                              margin: '0 0 12px',
                              display: 'flex',
                            }}
                          >
                            <Label className="mt-3 mb-2">
                              Text position block
                            </Label>
                            <Label>
                              <input
                                type="radio"
                                name="textPosition"
                                value="top"
                                checked={this.state.textPosition === 'top'}
                                onChange={this.handleTextPositionChange}
                              />
                              Text Top&nbsp;&nbsp;&nbsp;
                              <input
                                type="radio"
                                name="textPosition"
                                value="bottom"
                                checked={this.state.textPosition === 'bottom'}
                                onChange={this.handleTextPositionChange}
                              />
                              Text Bottom
                            </Label>
                          </div>
                        )}
                      {!this.state.isNoImage && (
                        <Label className="mt-3 mb-3">
                          Upload cover image (optional)
                        </Label>
                      )}

                      {/* <img src={story} ></img> */}
                      {!this.state.isNoImage && (
                        <div>
                          {!imageUrl && (
                            <Dropzone
                              onDrop={this.onDropImages}
                              maxFiles={1}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  style={{
                                    height: '200px',
                                    fontWeight: '400',
                                    fontSize: '20px',
                                    border: '4px dashed #ddd',
                                    textAlign: 'center',
                                    padding: '30px',
                                  }}
                                  {...getRootProps()}
                                >
                                  <input
                                    name="photos"
                                    id="photos"
                                    {...getInputProps()}
                                    accept="image/jpg, image/jpeg, image/png"
                                    minsize="0"
                                    maxsize="5242880"
                                  />
                                  Drop files here to upload
                                  <p
                                    style={{
                                      font: 'normal normal 300 1.1rem / 1.5 Fira Sans',
                                      color: '#404040',
                                      lineHeight: '1.5',
                                      pageBreakInside: 'avoid',
                                      marginTop: '0',
                                      margin: '14px 29px 17px',
                                    }}
                                  >
                                    or
                                  </p>
                                  <button
                                    type="button"
                                    className="white-button"
                                  >
                                    SELECT FILES
                                  </button>
                                </div>
                              )}
                            </Dropzone>
                          )}

                          {imageUrl && (
                            <div
                              style={{
                                width: '150px',
                                height: '150px',
                                marginRight: '0px',
                                border: '1px solid rgba(0, 0, 0, .07)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '25px',
                              }}
                            >
                              <img
                                // src={imageCheckUrl}
                                src={typeof imageUrl == 'string' ? imageUrl : URL.createObjectURL(imageUrl)}
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  height: 'auto',
                                  display: 'block',
                                }}
                                alt="cover"
                                onLoad={() => {
                                  console.log('Imageurll====', imageUrl)
                                }}
                              />
                              <span
                                onClick={this.handleDeleteImage}
                              // style={{
                              //   position: "absolute",
                              //   top: "5px",
                              //   right: "5px",
                              //   cursor: "pointer",
                              //   fontSize: "20px",
                              //   color: "red", // Or use any color you prefer
                              //   background: "white", // Optional, to make it stand out
                              //   borderRadius: "50%",
                              //   padding: "2px 5px",
                              // }}
                              >
                                {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg> */}
                              </span>

                              <img
                                onClick={this.handleDeleteImage}
                                style={{
                                  // content: "\f335",
                                  marginTop: '-120px',
                                  marginLeft: '-13px',
                                }}
                                src={imgDelete}
                                width="20"
                                height="20"
                                alt=""
                              ></img>
                            </div>
                          )}
                        </div>
                      )}
                      <Label className="mt-3">Show in public bookshelf?</Label>
                      <div className="toggle-switch">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={!!this.state.isChecked}
                            onChange={this.toggleCheckboxChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}></Col>

                    <Col
                      xs={6}
                      style={{
                        padding: '0px 0px 20px 148px',
                        textAlign: 'center',
                      }}
                    >
                      <button type="submit" className="custom-button">
                        {UpdateMyBook}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="feather feather-chevron-right"
                          fill="none"
                          height="24"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <polyline points="9 18 15 12 9 6" />
                        </svg> */}
                      </button>
                      {this.state.loading && (
                        <div
                          className="loading-spinner"
                          style={{ marginLeft: '10px' }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>

            <div className="change_book_design">
              <h2 className="center-with-line"> Change your story design</h2>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}
              >
                <TemplateSelector
                  style={{ width: '33%' }}
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeIndex - 1 + images.length) % images.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick((activeIndex + 1) % images.length)
                  }
                  activeIndex={activeIndex}
                  images={images}
                  currentImage={currentImage}
                  handleSelected={(index) => this.handleButtonClick(index)}
                />

                <TemplateSelector
                  heddings="Insert image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeInsertImageIndex - 1 + insertImages.length) %
                      insertImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeInsertImageIndex + 1) % insertImages.length
                    )
                  }
                  activeIndex={activeInsertImageIndex}
                  images={insertImages}
                  currentImage={currentInsertImage}
                  handleSelected={(index) =>
                    this.handleInsertImageButtonClick(index)
                  }
                />

                <TemplateSelector
                  heddings="No image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeNoImageIndex - 1 + noImages.length) %
                      noImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeNoImageIndex + 1) % noImages.length
                    )
                  }
                  activeIndex={activeNoImageIndex}
                  images={noImages}
                  currentImage={currentNoImage}
                  handleSelected={(index) =>
                    this.handleNoImageButtonClick(index)
                  }
                />
              </div>

              <div style={{ marginTop: '80px', width: '33%' }}>
                <TemplateSelector
                  heddings="Full image"
                  onClickPrev={() =>
                    this.handleButtonClick(
                      (activeFullIndex - 1 + fullImages.length) %
                      fullImages.length
                    )
                  }
                  onClickNext={() =>
                    this.handleButtonClick(
                      (activeFullIndex + 1) % fullImages.length
                    )
                  }
                  activeIndex={activeFullIndex}
                  images={fullImages}
                  currentImage={currentFullImage}
                  handleSelected={(index) => this.handleImageButtonClick(index)}
                />
              </div>
            </div>
          </CardBody>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { loggedInUserId: state.auth.session.user.id }
}

const mapDispatchToProps = {
  listMyStory: myStoryOperations.listMyStory,
  getMyStory: myStoryOperations.getMyStory,
  updateMyStory: myStoryOperations.updateMyStory,
}
export default connect(mapStateToProps, mapDispatchToProps)(EditMyStories)
