import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import logo from '../../assets/images/logo/logo2.png'
import './Footer.css'

const Footer = () => {

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === null) {
        window.location.reload();
        // Handle the case where localStorage is cleared
        console.log('LocalStorage cleared in another tab');
        // You might want to trigger a state update or a re-fetch of data here
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const clearLocalStorage = () => {
    localStorage.clear();
    window.dispatchEvent(new Event('storage'));
    window.location.reload();
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === null) {
        window.location.reload();
        // Handle the case where localStorage is cleared
        console.log('LocalStorage cleared in another tab');
        // You might want to trigger a state update or a re-fetch of data here
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const divStyle = {
    fontFamily: 'Fira Sans',
    padding: '1% 1% 3% 1%',
    fontWeight: '500',
    color: '#fff',
  }
  return (
    <Fragment>
      <div className="footer-wrapper">
        <footer className="footer mt-5">
          <Container>
            <Row>
              <div className="col-12 col-md-6 col-lg-3 footer-1">
                <div className="mb-2">NAVIGATION</div>
                <Link to="#">
                  <div style={divStyle}>About</div>
                </Link>
                <Link to="#">
                  <div style={divStyle}>Privacy</div>
                </Link>
                <Link to="#">
                  <div style={divStyle}>Contact</div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2 footer-2">
                <div className="mb-2">SOCIAL</div>
                <Link to="#">
                  <div style={divStyle}>
                    <i className="fa fa-facebook"></i>&nbsp;&nbsp;&nbsp;Facebook
                  </div>
                </Link>
                <Link to="#">
                  <div style={divStyle}>
                    <i className="fa fa-twitter"></i>&nbsp;&nbsp;Twitter
                  </div>
                </Link>
                <Link to="#">
                  <div style={divStyle}>
                    <i className="fa fa-instagram"></i>&nbsp;&nbsp;Instagram
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-5 footer-3">
                <div className="mb-2">BLOGS</div>
                <Link to="#">
                  <div style={divStyle}>View all blogs</div>
                </Link>
              </div>

              <div className="col-12 col-md-6 col-lg-2 footer-4">
                <div className="mb-2 footerLogoRight">
                  <img src={logo} height={40} width={140} alt="logo"></img>
                </div>
                <div
                  style={{
                    fontFamily: 'Fira Sans',
                    padding: '1% 1% 3% 1%',
                    fontWeight: '400',
                    color: '#fff',
                  }}
                >
                  A unique gift that lasts
                </div>
              </div>
            </Row>
          </Container>
        </footer>
        <footer className="secondary-footer">
          <Container>
            <Row>
              <div className="col-md-6">
                <Link onClick={clearLocalStorage}>
                  <div
                    className="mb-2"
                    style={{
                      color: '#fff',
                      fontSize: '13px',
                      fontFamily: 'Fira Sans',
                    }}
                  >
                    LOG OUT
                  </div>
                </Link>
                <div
                  style={{ fontSize: '10px', color: 'rgba(255,255,255,0.5)' }}
                >
                  © 2024 Gift Your Story. All rights reserved.
                </div>
              </div>
            </Row>
          </Container>
        </footer>
      </div>
    </Fragment>
  )
}

export default Footer
