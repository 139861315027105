import React from 'react';
import PropTypes from 'prop-types';
import './TemplateSelector.css';

const TemplateSelector = ({onClickPrev,onClickNext,activeIndex,images,currentImage,handleSelected,heddings='Default style'}) => {
  
 

  return (
    
    <>
    <div className="row">
      <div className="">
        <div className="slider-container-39 text-center mb-5">
          <div className="tns-outer" id="tns1-ow">
            <div className="tns-controls" aria-label="Carousel Navigation" tabIndex="0">
              <button
                data-controls="prev"
                tabIndex="-1"
                aria-controls="tns1"
                onClick={onClickPrev} // Previous button
              >
                Prev
              </button>
              <button
                data-controls="next"
                tabIndex="-1"
                aria-controls="tns1"
                onClick={onClickNext} // Next button
              >
                Next
              </button>
            </div>
            <div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">
              "Slide "
              <span className="current">{activeIndex + 1}</span>
              " of {images.length}"
            </div>
            <h3>{heddings}</h3>
            <div className="tns-item tns-slide-active tns-fadeIn gys-active tns-liveregion " id="tns1-item" style={{ left: "0%" }}>
              <h4>{currentImage.alt}</h4>
              <img
                data-count={activeIndex}
                data-blankcover
                // data-bookid="3157"
                data-slug={`${currentImage.alt.toLowerCase()}-3`}
                data-term="default-style"
                data-template-cat={`book_category-${currentImage.alt.toLowerCase()}-design`}
                className="cover_img"
                src={currentImage.src}
                alt={currentImage.alt} 
                onClick={() => handleSelected(activeIndex)}
                />
                
            </div>
          
            <div className="tns-nav" aria-label="Carousel Pagination">
              {images.map((v, index) => (

                <button 
                key={v.id || index}
                data-nav={index}
                  aria-controls="tns1"
                  aria-label={`Carousel Page ${index + 1} ${activeIndex === index ? '(Current Slide)' : ''}`}
                  className={activeIndex === index ? 'tns-nav-active' : ''}
                  style={{ backgroundColor: v.backgroundColor, height:"25px", width:"25px", borderRadius:"100px", margin: "5px", position: "relative"}}
                  onClick={() => handleSelected(index)}
                >
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
   </>
  );
};




export default TemplateSelector;
